:root {
  --nmgreen: #95bf34;
  --nmlbue: #033d6f;
  --nmgrey: #434546;
}

/* Header styling: */

.header-top {
  display: flex;
  align-items: center;
  background-color: #DDE0E3;
  height: 39px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
  justify-content: space-between;
}

.header-language-icon {
  cursor:pointer;
}

.header-top-email {
  display: flex;
  align-items:center;
  justify-content: space-between;
}

.header a, a:hover, a:active, a:focus {
  text-decoration: none;
}

.header-top-email {
  color:#434546;
}

.header-top img {
  padding-right: 5px;
}

.header-bottom {
  padding:20px 15px;
  height: 100px;
  box-shadow: 0 0 20px #033d7028;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.header-logo {
  height: 38.03px;
}

.header p {
  font-size: 32px;
  padding-left: 54px;
}

/* Content styling: */

.main-content {
  display: flex;
  flex-grow: 1;
}


/* Sidebar styling: */
.sidebar {
 min-width: 175px;
 background-color: #DDE0E3;

}

.sidebar--ul {
  list-style: none;
  cursor:pointer;
  user-select: none;
}

.sidebar--li {
  padding: 10px;
}

.sidebar--li:hover {
  background-color: #95bf3475;
  color:white;
}
.sidebar--li--active {
  background-color: #95BF34;
  color:white;
  padding: 10px;
}

/* Main content styling: */
.main-content-main {
  flex-grow: 1;
  width: 100%;
}

.main-available-number-display {
  display: grid;
  grid-template-columns: [number] 150px [button] 200px;
  row-gap: 15px;
  column-gap: 10px;
  margin-bottom: 20px;
}

.main-available-single-number {
  align-self: center;
}

.choose-vir-number-button, .button-default {
  background: linear-gradient(to right, #71bf8b, #95bf34);
  height: 30px;
  border-style: none;
  border-radius: 5px;
  padding: 0 8px;
  color: white;
  cursor:pointer;
}


.choose-vir-number-button-notavailable {
  background: #033d6f;
  height: 30px;
  border-style: none;
  border-radius: 5px;
  padding: 0 8px;
  color: white;
  cursor:pointer;
} 

.choose-vir-number-button:hover, .button-default:hover {
  background: linear-gradient(to left, #71bf8b, #95bf34);
}

.choose-vir-number-button-active-masked {
  background: #033d6f;
  height: 30px;
  border-style: none;
  border-radius: 5px;
  padding: 0 8px;
  color: white;
  cursor:pointer;
}

.button-to-configure {
  background:var(--nmlbue)
}

.button-to-configure:hover {
  background:var(--nmlbue)
}


.main h1 {
  font-size: 24px;
  margin-bottom: 15px;
}

.main h2 {
  font-size: 20px;
}

.main {
  padding: 15px;
}

.choose-vir-number h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.manage-number-modal {
  color: rgb(0, 0, 0);
  padding: 10px;
  min-width: 520px;
  min-height: 245px;
  background-color: whitesmoke;
  border-radius: 10px;
  margin:20px;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.534);
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: nowrap;
}

.manage-number-modal h2 {
  font-size: 24px;
}

.manage-number-modal h3 {
  margin-top:10px;
  font-size: 16px;
}

.manage-number-modal p {
  margin-top:5px;
}

.manage-number-modal input {
  height: 25px;
  width: 330px;
  border: var(--nmgrey) solid 1.5px;
  border-radius: 4px;
  text-align: center;
  vertical-align:middle;
  color:#434546;
  font-size:15.5px;
  padding:0;
  margin: 0;
}


.modal-button {
  min-width: 150px;
  margin-left:10px;
}


.modal-button-inactive {
  min-width: 150px;
  margin-left:10px;
  background: linear-gradient(to right, #71bf8b3d, #95bf343b);
  cursor:none;
}

.modal-button-inactive:hover {
  min-width: 150px;
  margin-left:10px;
  background: linear-gradient(to right, #71bf8b3d, #95bf343b);
}

.modal-closure {
  margin-top:40px;
  display: flex;
  justify-content: center;
}



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.modal-length-check-display {
  color: red;
  position: relative;
  left:10px;

}

.apitesting button {
  margin:10px;
}


.delete-masking {
  background: linear-gradient(to right, #e25b5b, #ff0000);
  min-width: 150px;
  height: 30px;
  border-style: none;
  border-radius: 5px;
  padding: 0 8px;
  color: white;
  cursor:pointer;
  margin-left:10px;
}



.delete-masking:hover {
  background: linear-gradient(to left, #e25b5b, #ff0000);
}

.stats-table-container {
  display:flex;
  height: 800px;
  margin-bottom: 40px;
}

.MuiDataGrid-row.Mui-odd {
  background-color: #d3daed;
}

.MuiDataGrid-columnHeaders {
  background-color: #7fc97f;
}


.stats-choose-button {
  font-size: 15px;
  background: linear-gradient(to right, #71bf8b, #95bf34);
  height: 40px;
  width: 100px;
  border-style: none;
  border-radius: 5px;
  padding: 0 8px;
  color: white;
  cursor:pointer;
  transition: font-size 0.35s;
}

.stats-choose-button-chosen {
  font-size: 22px;
  background: #033d6f;
  height: 40px;
  width: 100px;
  border-style: none;
  border-radius: 5px;
  padding: 0 8px;
  color: white;
  cursor:pointer;
} 

.stats-choose-button:hover {
  background: linear-gradient(to left, #71bf8b, #95bf34);
  font-size: 22px;
}

.stats-options-picker {
  width: 600px;
  margin:20px 15px;
  display: flex;
  align-items: center;
  justify-content:flex-start;
}


.choose-vir-number-button:hover {
  background: linear-gradient(to left, #71bf8b, #95bf34);
}

.stats-load-button {
  background: linear-gradient(to right, #71bf8b, #95bf34);
  height: 30px;
  width: 200px;
  border-style: none;
  border-radius: 5px;
  padding: 0 8px;
  color: white;
  cursor:pointer;
}



.stats-load-button:hover {
  background: linear-gradient(to left, #71bf8b, #95bf34);
}


.stats-content   {
  font-size: 18px;
  margin-top:20px;
  font-style: normal;
}

path  {
  color:#033d6f
}

.apiReturnMessage {
  font-size: 20px;
}

.stats-barChart {
  height: 350px;
}


.docu-tech table, th, td {
  border: 1px solid #7fc97f;
  border-collapse: collapse;
} 

.docu-tech {
  margin-bottom: 50px;
  padding-left:10px;
  padding-right:10px;
}

.docu-tech table {
  width: 100%;
  margin-left:10px;
  margin-right: 10px;
  margin-top: 12px;
}

.docu-tech h1 {
  margin-top: 20px;
}


.docu-tech a:link {
  text-decoration: none;
  color:#7fc97f;
    font-size: 18px;
  }
  
.docu-tech a:visited {
  color:#7fc97f;
  text-decoration: none;
}

.docu-tech  a:hover {
  text-decoration: underline;
}

.docu-tech  a:active {
  text-decoration: underline;
}


p.modal-api-error {
  /* margin:0; */
  margin-left:10px;
  /* padding:0; */
  text-align:center;
  color:#c49797;
}
h5.apiReturnMessage {
  font-style: normal;
  font-size:15px;
  margin-top:15px;
  margin-left:15px;
}

/* Footer styling: */
.footer {
  color: white;
  font-size: 12px;
  background-color: #172d3e;
  /* height: 45px; */
  min-height: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* margin-top:auto; */
  
}

.footer-link {
  padding-left:12px;
}

.footer a, a:hover, a:active, a:focus {
  color: white;
  text-decoration: underline;
}